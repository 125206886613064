import { render, staticRenderFns } from "./UISelect.vue?vue&type=template&id=06aedbfa&scoped=true"
import script from "./UISelect.vue?vue&type=script&lang=js"
export * from "./UISelect.vue?vue&type=script&lang=js"
import style0 from "./UISelect.vue?vue&type=style&index=0&id=06aedbfa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06aedbfa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/LORGAR/LORGAR_Deploy_Prod/components/ui/UIIcon.vue').default})
